import React from 'react'
import { UserInfo } from '../api/services/umapi'
import { AppContext } from '../AppContext'
import userIcon from '../images/icon-user.svg'
import './UserMenu.css'

interface Props {
    user?: UserInfo
    onSettingsClick: () => void
    onLogout: () => void
}

interface State {
    opened: boolean
}

export default class UserMenu extends React.PureComponent<Props, State> {
    public static contextType = AppContext
    public state: State = { opened: false }
    public context!: React.ContextType<typeof AppContext>

    private node = React.createRef<HTMLDivElement>()

    public renderInitials (): JSX.Element {
        if (!this.props.user) { return <img src={userIcon} width={ 24 } /> }

        return <span>{ this.props.user.firstName[0] + this.props.user.lastName[0] }</span>
    }

    public toggle (state?: boolean): void {
        this.setState({ opened: (state === undefined ? !this.state.opened : state) })
    }

    public handleSettingsClick = (): void => {
        this.props.onSettingsClick()
        this.toggle(false)
    }

    public handleLogoutClick = (): void => {
        this.props.onLogout()
        this.toggle(false)
    }

    public handleClickOutside: EventListener = evt => {
        if (this.state.opened) {
            if (this.node.current && this.node.current.contains(evt.target as Node)) { return }
            this.toggle(false)
        }
    }

    public renderMenu (user?: UserInfo): JSX.Element {
        if (user) {
            return (
                <div className='user-menu'>
                    <div className='user-card'>
                        <div className='user-initials'>{ this.renderInitials() }</div>
                        <div className='fullname'>{ user.fullName }</div>
                    </div>
                    <ul>
                        <li>
                            <button onClick={ this.handleSettingsClick }>
                                { this.context.l.userMenu.settings }
                            </button>
                        </li>
                        <li>
                            <button onClick={ this.handleLogoutClick }>
                                { this.context.l.userMenu.logout }
                            </button>
                        </li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div className='user-menu no-card'>
                    <ul>
                        <li>
                            <form action='/_oauth/login' method='POST'>
                                <button>{this.context.l.userMenu.login}</button>
                            </form>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    public componentWillMount (): void {
        document.addEventListener('mousedown', this.handleClickOutside, false)
    }

    public componentWillUnmount (): void {
        document.removeEventListener('mousedown', this.handleClickOutside, false)
    }

    public render (): JSX.Element {
        return (
            <div ref={ this.node } className={ 'UserMenu' + (this.state.opened ? ' opened' : '') }>
                <div className='user-initials' onClick={ (): void => this.toggle() }>
                    { this.renderInitials() }
                </div>
                { this.state.opened ? this.renderMenu(this.props.user) : '' }
            </div>
        )
    }
}
