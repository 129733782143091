import React from 'react'
import { AppCtxO, withContext } from '../AppContext'
import { Course } from '../courses'
import './CoursesItem.css'

interface Props {
    course: Course
}

function renderCourseTags (course: Course, tagsLabels: { [tag: string]: string }): JSX.Element[] {
    const tagsKeys: Array<(keyof Course)> = [ 'department', 'season', 'programmeType' ]
    return tagsKeys
        .filter(key => (key in course && course[key]))
        .map(key => {
            const skey = course[key]!.toString()
            const label = tagsLabels[skey] ? tagsLabels[skey] : course[key]
            return <li key={ key }>{ label }</li>
        })
}

const CoursesItem: React.FC<Props & AppCtxO> = ({ course, ctx: { l } }) => (
    <li className={'CoursesItem' + (course.url ? ' link' : '')}>
        <div className='column-info'>
            <a href={ course.url }>
                <div className='code'>{ course.code }</div>
                <div className='name'>{ course.name }</div>
                <div className='url'>{ course.url }</div>
                <ul className='tags'>
                    { renderCourseTags(course, l.courses.tags) }
                </ul>
            </a>
        </div>
    </li>
)

export default withContext<Props>(CoursesItem)
