import React from 'react'
import { AppContext } from '../AppContext'
import { Course, CourseList, fromCourseList } from '../courses'
import './Courses.css'
import CoursesItem from './CoursesItem'
import CoursesStudyingItem from './CoursesStudyingItem'
import CoursesTeachingItem from './CoursesTeachingItem'

export type ListStyle = 'teaching' | 'studying' | 'default'

interface Props {
    title?: string
    courses?: CourseList | Course[]
    style: ListStyle
    maximum?: number
}

interface State {
    displayAll: boolean
}

export default class Courses extends React.Component<Props, State> {

    public static contextType = AppContext
    public state: State = {
        displayAll: false,
    }
    public context!: React.ContextType<typeof AppContext>

    public limitCourses = (courses: Course[], maximum?: number): Course[] => {
        return maximum && !this.state.displayAll
            ? courses.slice(0, this.props.maximum)
            : courses
    }

    public getCourses = (): Course[] => {
        const obj = this.props.courses
        if (!obj) { return [] }

        return (typeof obj === 'object' && !(obj instanceof Array))
            ? fromCourseList(obj)
            : obj
    }

    public renderShowAllButton = (coursesCount: number): JSX.Element | null => {
        if (this.state.displayAll || !this.props.maximum || coursesCount < this.props.maximum) { return null }
        return (
            <div className='showall'>
                <button onClick={ this.handleShowAllClick } className='button'>
                    {this.context.l.courses.showAll}
                </button>
            </div>
        )
    }

    public handleShowAllClick = (): void => {
        this.setState({
            displayAll: true,
        })
    }

    public renderItem = (course: Course, style: ListStyle): JSX.Element => {
        switch (style) {
        case 'studying':
            return <CoursesStudyingItem course={ course } key={ course.code } />
        case 'teaching':
            return <CoursesTeachingItem course={ course } key={ course.code } />
        default:
            return <CoursesItem course={ course } key={ course.code } />
        }
    }

    public render (): JSX.Element | null {
        const courses = this.getCourses()
        const isShortened = this.props.maximum && !this.state.displayAll && courses.length > this.props.maximum

        if (courses.length === 0) { return null }

        return (
            <section className='Courses'>
                {this.props.title ? (<h2>{ this.props.title }</h2>) : null}
                <ul className={ 'list' + (isShortened ? ' shortened' : '')}>
                    { this.limitCourses(courses, this.props.maximum).map(course => (
                        this.renderItem(course, this.props.style)
                    )) }
                </ul>
                { this.renderShowAllButton(courses.length) }
            </section>
        )
    }
}
