import React from 'react'
import camelCase from 'lodash.camelcase'
import { TimetableEvent } from '../api/services/sirius'
import { AppCtxO, AppPreferences, withContext } from '../AppContext'
import { EventType } from '../api/services/sirius'
import { Locale } from '../locales'
import locationIcon from '../images/icon-location.svg'
import './Upcoming.css'

interface Props {
    events?: TimetableEvent[]
}

const currentDate = new Date(Date.now())

function getEventTimeRelation (event: TimetableEvent): ('future' | 'now' | 'past') {
    if (event.startsAt > currentDate && event.endsAt > currentDate) { return 'future' }
    if (event.endsAt > currentDate) { return 'now' }
    return 'past'
}

function renderDisplayedDate (tomorrow: boolean, labels: string[]): JSX.Element {
    const date = tomorrow ? new Date(Date.now() + 86400000) : currentDate
    return (
        <div className='date'>
            <span className='day'>{ date.getDate() }</span>
            <span className='month'>{ labels[date.getMonth()] }</span>
        </div>
    )
}

function renderTime (date: Date): JSX.Element {
    return <span>{ date.getHours() }:{ date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() }</span>
}

function renderEvent (event: TimetableEvent, l: Locale, pref: AppPreferences): JSX.Element {
    let courseName
    if (event.course !== undefined) {
        courseName = <a href={ event.course.url }>{ event.courseName }</a>
    } else if (event.courseName !== undefined ) {
        courseName = <a href={ '/' + event.courseName }>{ event.courseName }</a>
    } else {
        const name = event.name && event.name[pref.locale]
        courseName = name ? <span title={name}>{name}</span> : undefined
    }

    return (
        <li className={ 'event ' + getEventTimeRelation(event) } key={ event.id }>
            <div className='name'>{ courseName }</div>
            <div className='time'>{ renderTime(event.startsAt) }—{ renderTime(event.endsAt) }</div>
            { event.room ? (<div className='location'>
                <img src={ locationIcon } width='14' />
                { event.room }
            </div>) : '' }
            <div className='type'>
                <span>{ l.eventTypes[camelCase(event.eventType) as EventType] }</span>
            </div>
        </li>
    )
}

function renderTruncatedSign (amount: number): JSX.Element {
    if (amount < 0) {
        return (<li className='truncated past'>
            &hellip;
        </li>)
    } else {
        return (<li className='truncated'>
            { amount } more
        </li>)
    }
}

function filterEvents (events: TimetableEvent[], forTomorrow: boolean): TimetableEvent[] {
    return events.filter(event => {
        // if today events should be displayed, match same date as today, otherwise match any other date
        return event.startsAt.getDate() === currentDate.getDate()
            ? !forTomorrow
            : forTomorrow
    })
}

type TruncatedEvents = { events: TimetableEvent[], filteredNext: number, filteredPast: number }

function truncateEvents (events: TimetableEvent[]): TruncatedEvents {
    let overlimit = events.length - 8
    let filteredPast = 0
    let filteredNext = 0

    return { events: events.filter(event => {
        if (overlimit <= 0) { return true }
        if (getEventTimeRelation(event) === 'past') { filteredPast++ } else { filteredNext++ }
        overlimit--
        return false
    }), filteredNext, filteredPast }
}

const Upcoming: React.FC<Props & AppCtxO> = ({ events, ctx: { l, pref } }) => {
    // determines if tomorrow events should be displayed
    let upcomingTomorrow = currentDate.getHours() >= 20

    if (!events || events.length === 0) {
        return <section className='Upcoming empty'>
            <h2>{ l.upcoming.genericTitle }</h2>
            <p className='no-events'>{ l.upcoming.noEvents }</p>
        </section>
    }

    // If today's events are empty, display tomorrow's instead
    if (!upcomingTomorrow && filterEvents(events, upcomingTomorrow).length === 0) { upcomingTomorrow = true }

    const { events: eventsToDisplay, filteredPast, filteredNext } =
        truncateEvents(filterEvents(events, upcomingTomorrow))

    return (
        <section className={ 'Upcoming' + (upcomingTomorrow ? ' tomorrow' : '')}>
            { upcomingTomorrow ? <h2>{ l.upcoming.tomorrowTitle }</h2> : <h2>{ l.upcoming.todayTitle }</h2>}

            <div className='timeline'>
                <div className='current-indicator' />
                { renderDisplayedDate(upcomingTomorrow, l.upcoming.months)}
                <ul>
                    { filteredPast > 0 ? renderTruncatedSign(-1 * filteredPast) : null}
                    { eventsToDisplay.map(event => renderEvent(event, l, pref))}
                    { filteredNext > 0 ? renderTruncatedSign(filteredNext) : null}

                </ul>
            </div>

            <div className='see-more'>
                <a href='http://timetable.fit.cvut.cz' target='_blank' rel='noopener noreferrer'>
                    { l.upcoming.seeMore }
                </a>
            </div>
        </section>
    )
}

export default withContext<Props>(Upcoming)
