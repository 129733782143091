import config from '../../../config.json'
import { Course, Semester } from '../../courses'
import { makeAuthenticatedRequest } from '../index'

const ENDPOINT_URL = config.api.endpoints.coursePagesData

const mapToCourse = ([code, obj]: [string, any]): Course => ({
    code,
    department: obj.department,
    grades: obj.grades ? { // TODO: make sure that this really detects empty fields
        url: obj.grades,
    } : undefined,
    name: obj.nameCs, // FIXME: according to app language
    programmeType: obj.programmeType,
    repository: obj.pagesRepo ? {
        url: obj.pagesRepo,
    } : undefined,
    season: obj.season,
    url: obj.homepage,
})

export async function getPublicCourses (semester: Semester = 'all'): Promise<Course[]> {
    const data = await makeAuthenticatedRequest({
        method: 'GET',
        url: `${ENDPOINT_URL}courses-${semester}.json`,
    })

    return Object.entries(data.courses).map(mapToCourse)
}
