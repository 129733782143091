import axios, { AxiosRequestConfig } from 'axios'
import { Course, CourseList, toCourseList, splitCourseListByRoles, mergeCourseLists } from '../courses'
import { getCourseNews, getCoursesRepositoriesData } from './services/cpagesApi'
import { getPublicCourses } from './services/cpagesData'
import { getClassifications } from './services/grades'
import { getUserEvents, TimetableEvent } from './services/sirius'
import { getUserInfo, UserInfo } from './services/umapi'

export interface UserCredentials {
    username: string
    accessToken: string
    refreshToken: string
}

export interface DownloadConfiguration {
    session?: UserCredentials
    handlers: {
        updateAllCourses: (courses: CourseList) => void
        saveUser: (user: UserInfo) => void
        saveUserEvents: (events: TimetableEvent[]) => void
        error: (reason: any) => void
    }
}

/**
 * Downloads CourseList, by merging various data from many APIs
 * into single structure. It also produces side effects to push some updates
 * of data, while waiting to finish requests to other data.
 *
 * Function first gets public course data. If user is logged, then gets
 * user info. After user info is retrieved, it gets any additional
 * courses information.
 *
 * Function has to respect the data dependency (as described in
 * /docs/api-data-dependency-diagram.png).
 *
 * @param config Download configuration object
 * @returns A CourseList promise.
 */
export function downloadCourses (config: DownloadConfiguration): Promise<CourseList> {
    function onReqReject<T> (defaultValue: T): (reason: any) => T { return (reason): T => {
        config.handlers.error(reason); return defaultValue
    } }

    // Request public_data
    return getPublicCourses().then((publicData: Course[]) => {
        // Continue if logged in...
        if (!config.session) { return toCourseList(publicData) }

        config.handlers.updateAllCourses(toCourseList(publicData))

        // Request user_info
        return getUserInfo(config.session)
            .then((user: UserInfo): Promise<Course[][]> => {
                config.handlers.saveUser(user)

                // Split by studying/teaching
                const splitted = splitCourseListByRoles(toCourseList(publicData), user.courseRoles)

                // Verify session still exists
                if (!config.session) { return Promise.resolve([publicData]) }

                // Request user_events
                getUserEvents(config.session).then(config.handlers.saveUserEvents)

                // Request rest of courses additionals at once
                return Promise.all([
                    getClassifications(config.session).catch(onReqReject([])),
                    getCoursesRepositoriesData(splitted.teacher).catch(onReqReject([])),
                    getCourseNews().catch(onReqReject([])),
                ])
            })
            .then((courses: Course[][]) => {
                return courses.reduce<CourseList>(
                    (acc, curr) => mergeCourseLists(acc, toCourseList(curr)), toCourseList(publicData)
                )
            }).catch(onReqReject(toCourseList(publicData)))
    }).catch(onReqReject({}))
}

/**
 * This function makes it easier to send request with user credentials.
 * @param config Axios request configuration object (overrides)
 * @param cred User credentials
 */
export async function makeAuthenticatedRequest (config: AxiosRequestConfig, cred?: UserCredentials): Promise<any> {
    const headers = cred
        ? { Authorization: 'Bearer ' + cred.accessToken }
        : { }

    const { status, data } = await axios({
        ...config,
        headers: {
            ...config.headers,
            ...headers,
        },
    })

    if (status !== 200) { throw new Error('Response status was ' + status + '\n' + data) }
    return data
}
