import React from 'react'
import { AppContext } from '../AppContext'
import './Settings.css'

import Footer from './Footer'

type OnChangeFunc = (key: any, value: any) => any

interface Props {
    onClose: () => void
    onSettingChange: OnChangeFunc
}

interface State {
    opened: boolean
}

const Choice: React.FC<{ name: string, choices: any, current: any, onChange: OnChangeFunc }> =
    ({ name, choices, current, onChange }) => {
        const change = (e: React.ChangeEvent<HTMLSelectElement>): void => onChange(name, e.target.value)
        return <div className='choice'>
            <select onChange={change}>
                { Object.entries(choices).map(entry => (
                    <option value={ entry[0] } selected={ entry[0] === current } key={ entry[0] }>
                        { (entry[1] as any).toString() }
                    </option>)) }
            </select>
        </div>
    }

const Switch: React.FC<{ name: string, labels: [string, string], current: boolean, onChange: OnChangeFunc }> =
    ({ name, labels: [off, on], current, onChange }) => {
        const change = (e: React.ChangeEvent<HTMLInputElement>): void => onChange(name, e.target.value === 'on')
        return <div className='switch'>
            <input
                type='radio' name={ name } id={ name + '-off' }
                checked={ !current } onChange={ change } value={ 'off' } />
            <label htmlFor={ name + '-off' }>{ off }</label>
            <input
                type='radio' name={ name } id={ name + '-on' }
                checked={ current } onChange={ change } value={ 'on' }  />
            <label htmlFor={ name + '-on' }>{ on }</label>
        </div>
    }

export default class Settings extends React.Component<Props, State> {
    public static contextType = AppContext
    public state: State = { opened: false }
    public context!: React.ContextType<typeof AppContext>

    private node = React.createRef<HTMLDivElement>()

    public toggle (state?: boolean): void {
        this.setState({ opened: (state === undefined ? !this.state.opened : state) })
    }

    public handleClickOutside: EventListener = evt => {
        if (this.state.opened) {
            if (this.node.current && this.node.current.contains(evt.target as Node)) { return }
            this.toggle(false)
        }
    }

    public componentWillMount (): void {
        document.addEventListener('mousedown', this.handleClickOutside, false)
    }

    public componentWillUnmount (): void {
        document.removeEventListener('mousedown', this.handleClickOutside, false)
    }

    public render (): JSX.Element | null {
        const { pref, l } = this.context

        if (!this.state.opened) { return null }

        return (
            <div className='Settings' ref={ this.node }>
                <div className='container'>
                    <button className='button button-light close' onClick={ this.props.onClose }>
                        { l.settings.close }
                    </button>
                    <h1>{ l.settings.title }</h1>
                    { l.settings.description }

                    <h2>{ l.settings.generalTitle }</h2>
                    <form>
                        <dl>
                            <dt>{ l.settings.items.language }</dt>
                            <dd>
                                <Choice
                                    name='locale'
                                    choices={{ cs: l.settings.language.cs, en: l.settings.language.en }}
                                    current={ pref.locale }
                                    onChange={ this.props.onSettingChange }
                                />
                            </dd>
                            <dt>{ l.settings.items.upcomingVisibility }</dt>
                            <dd>
                                <Switch
                                    name='upcomingVisible'
                                    labels={ [ l.settings.hide, l.settings.show ] }
                                    current={ pref.upcomingVisible }
                                    onChange={ this.props.onSettingChange }
                                />
                            </dd>
                        </dl>
                    </form>

                    <button className='button save' onClick={ this.props.onClose }>
                        { l.settings.save }
                    </button>
                </div>
                <Footer style='minimal' />
            </div>
        )
    }
}
