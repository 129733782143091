import React from 'react'
import { AppContext } from '../AppContext'
import iconWarning from '../images/icon-warning.svg'
import './ErrorList.css'

interface Props {
    errors: string[]
}

interface State {
    visible: boolean
}

export default class UserMenu extends React.PureComponent<Props, State> {
    public static contextType = AppContext
    public state: State = { visible: false }
    public context!: React.ContextType<typeof AppContext>

    public show = (): void => {
        this.setState({
            visible: true,
        })
    }

    public hide = (): void => {
        this.setState({
            visible: false,
        })
    }

    public renderList (): JSX.Element {
        return (
            <ul className='list'>
                { this.props.errors.map((error, index) => (
                    <li key={ index }>{ error }</li>
                )) }
            </ul>
        )
    }

    public render (): JSX.Element {
        if (this.props.errors.length === 0) {
            return <div className='ErrorList empty'></div>
        }

        return (
            <div className={ 'ErrorList' + (this.state.visible ? ' visible' : '') }>
                <div className='icon' onMouseOver={this.show} onMouseLeave={this.hide}>
                    <img src={ iconWarning } width={24} />
                </div>
                { this.state.visible ? this.renderList() : null }
            </div>
        )
    }
}
