import sortBy from 'lodash.sortby'
import config from '../../../config.json'
import { Course } from '../../courses'
import { makeAuthenticatedRequest } from '../index'

const GITLAB_PROJPATH_REGEX = new RegExp('^https*://gitlab.fit.cvut.cz/(.+)/$')

const ENDPOINT_URL = config.api.endpoints.coursePagesApi

const mapToNews = (items: any[]): Course['news'] => sortBy(items.map((item: any) => ({
    content: item.title,
    date: item.publishedAt,
    id: item.id,
    link: item.url,
})), (item) => Date.parse(item.date)).reverse()

const mapToRepository = (data: any): Course['repository'] => {
    // FIXME: There are more valid statuses!
    const buildStatus = (data.pipeline && ['success', 'failed'].includes(data.pipeline.status))
        ? data.pipeline.status
        : undefined

    return {
        buildStatus,
        buildUrl: data.pipeline && data.pipeline.webUrl,
        latestIssues: data.issues.unassigned.latest.map((issue: any) => (issue.title)),
        unassignedIssues: parseInt(data.issues.unassigned.total, 10),
    }
}

const mapToCourse = ([code, obj]: [string, any]): Course => ({
    code,
    news: mapToNews(obj),
})

export async function getCourseNews (): Promise<Course[]> {
    const data = await makeAuthenticatedRequest({
        method: 'GET',
        params: { type: 'grouped', since: new Date().toISOString().slice(0, 10) },
        url: ENDPOINT_URL + 'cpages/news',
    })

    return Object.entries(data).map(mapToCourse)
}

export async function getCourseRepositoryData (code: string, projectPath: string): Promise<Course> {
    const data = await makeAuthenticatedRequest({
        method: 'GET',
        url: ENDPOINT_URL + `gitlab/projects/${projectPath}/info`, // FIXME: this needs to be read from somewhere
    })

    return { code, repository: mapToRepository(data) }
}

export async function getCoursesRepositoriesData (courses: Course[]): Promise<Course[]> {
    return Promise.all(courses
        .filter((course: Course) => course.repository && course.repository.url)
        .map((course: Course) => {
            const projectPathMatch = GITLAB_PROJPATH_REGEX.exec(course.repository!.url!)
            if (!projectPathMatch || projectPathMatch.length < 2) { return Promise.resolve(course) }
            return getCourseRepositoryData(course.code, projectPathMatch[1])
        }))
}
