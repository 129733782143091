import Fuse from 'fuse.js'
import clonedeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import uniq from 'lodash.uniq'
import { CourseUserRole, UserCourseList } from './api/services/umapi'
import { is } from './utils'

export interface Course {
    code: string
    name?: string
    url?: string
    semester?: string
    programmeType?: string
    season?: string
    department?: number
    news?: {
        id: string
        content: string
        link: string
        date: string
    }[]
    grades?: {
        url?: string
        grade?: string
        semesterPoints?: number
        assesPoints?: number
    }
    repository?: {
        url?: string
        buildStatus?: string
        buildUrl?: string
        unassignedIssues?: number
        latestIssues?: string[]
        lastChange?: { desc: string, author: string, time: number, url: string }
    }
}

export type Semester = 'current' | 'all' | 'B232' | 'B231' | 'B222' | 'B221' | 'B212' | 'B211' | 'B202' | 'B201' | 'B192' | 'B191' | 'B182' | 'B181' | 'B172' | 'B171' | 'B162' | 'B161' | 'B152' | 'B151'  // FIXME

export interface CourseList {
    [code: string]: Course
}

export function toCourseList (courses: Course[]): CourseList {
    return courses
        .filter(is)
        .reduce((list: CourseList, course: Course) => ({ ...list, [course.code]: course }), { })
}

export function fromCourseList (list: CourseList): Course[] {
    return Object.values(list)
}

export function mergeCourseLists (a: CourseList, b: CourseList): CourseList {
    return merge(clonedeep(a), b)
}

export function splitCourseListByRoles (list: CourseList, userRoles: CourseUserRole[]): UserCourseList {
    return userRoles
        .filter((role) => role.course in list)
        .reduce((roles, role) => (
            { ...roles, [role.role]: uniq([ ...roles[role.role], list[role.course]! ]) }
        ), { student: [], teacher: [] })
}

// Search functionality options (using Fuse.js)
const fuseOptions: Fuse.FuseOptions<Course> = {
    findAllMatches: true,
    keys: [
        { name: 'code', weight: 0.8 },
        { name: 'name', weight: 0.5 },
    ],
    location: 3,
    maxPatternLength: 16,
    shouldSort: true,
    threshold: 0.25,
}

// This exists so that we can search for "SZZ" and "SFE".
function addStateFinalExamCourses (courses: Course[]): Course[] {
    return [
        ...courses,
        {
            code: 'SZZ',
            name: 'Státní závěrečná zkouška',
            url: 'https://courses.fit.cvut.cz/SZZ',
        },
        {
            code: 'SFE',
            name: 'State Final Exam',
            url: 'https://courses.fit.cvut.cz/SFE',
        },
    ]
}

/**
 * Searches for a courses by given query, matching by code or name,
 * considering code match as more relevant than the name match.
 * @param courses Course list to be searched in
 * @param query Search query
 */
export function searchInCourses (courses: CourseList, query: string): Course[] {
    return (new Fuse(addStateFinalExamCourses(fromCourseList(courses)), fuseOptions)).search(query)
    // TODO: Debounce
    // TODO: Maybe use the fact, that it's { [code]: Course } for faster search?
}
