import React from 'react'
import { AppCtxO, withContext } from '../AppContext'
import logo from '../images/course-pages-logo-large.svg'
import './Heading.css'

const Heading: React.FC<AppCtxO> = ({ ctx }) => (
    <div className='Heading'>
        <img src={ logo } width='24' />
        <h1>{ ctx.l.apptitle }</h1>
    </div>
)

export default withContext<{}>(Heading)
