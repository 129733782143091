import config from '../../../config.json'
import { Course } from '../../courses'
import { makeAuthenticatedRequest, UserCredentials } from '../index'

const ENDPOINT_URL = config.api.endpoints.grades

const mapToCourse = (obj: any): Course => {
    const classifications: { [key: string]: any } = obj.overviewItems
        .filter((i: any) => (!(i.error) && i.value !== null))
        .reduce((all: { }, i: any) => ({ ...all, [i.classificationType]: i.value }), { })

    return {
        code: obj.courseCode,
        grades: {
            grade: classifications.FINAL_SCORE,
            semesterPoints: classifications.POINTS_TOTAL ? parseInt(classifications.POINTS_TOTAL, 10) : undefined,
        },
    }
}

export async function getClassifications (cred: UserCredentials): Promise<Course[]> {
    const data = await makeAuthenticatedRequest({
        method: 'get',
        url: ENDPOINT_URL + 'public/courses/classification-overview/' + cred.username,
    }, cred)

    return data.map(mapToCourse)
}
