import config from '../../config.json'
import React from 'react'
import { AppCtxO, AppPreferences, withContext } from '../AppContext'
import { Course } from '../courses'
import failedIcon from '../images/icon-build-failed.svg'
import successIcon from '../images/icon-build-success.svg'
import './CoursesItem.css'
import './CoursesItemDetailed.css'
import './CoursesTeachingItem.css'
import { Locale } from '../locales'
import { formatShortDate } from '../utils'


interface Props {
    course: Course
}

function renderEvents (pref: AppPreferences, news: Course['news']): JSX.Element[] | null {
    if (!news) { return null }

    return news.slice(0, config.news.maxItems).map((item, idx) => <li key={ 'news-' + idx }>
        <a href={ item.link }>
            <time dateTime={ item.date }>
                <strong>{ formatShortDate(pref.locale, item.date) }</strong>
            </time>&#8194;{ item.content }
        </a>
    </li>)
}

function renderUnassignedIssues (l: Locale, repository?: Course['repository']): JSX.Element | null {
    if (!repository || !repository.unassignedIssues || !repository.latestIssues) { return null }

    return (
        <li title={`${repository.unassignedIssues} ${l.issues.issuesTooltip}:\n${repository.latestIssues.join('\n')}`}>
            <a href={repository.url ? repository.url + 'issues?state=opened&assignee_id=None' : undefined}>
                <strong>{repository.unassignedIssues}</strong> { l.issues.unassignedIssues }
            </a>
        </li>
    )
}

function renderBuildStatus (l: Locale, repo?: Course['repository']): JSX.Element | null {
    if (!repo || !repo.url || !repo.buildUrl) { return null }

    // FIXME: Replace GitLab badge with custom icons once we have them.
    const repoSlug = repo.url.split('/').slice(3, -1).join('/')
    const badgeUrl = `${config.api.endpoints.coursePagesApi}gitlab/projects/${repoSlug}/badges/master/pipeline.svg`

    return (
        <div className='build-status'>
            <a href={ repo.buildUrl } target='_blank' title={ l.build.tooltip } rel='noopener noreferrer'>
                <img src={ badgeUrl } alt='Build status' width='116' height='20'></img>
            </a>
        </div>
    )
}

const CoursesTeachingItem: React.FC<Props & AppCtxO> = ({ course, ctx: { l, pref } }) => (
    <li className='CoursesTeachingItem CoursesItemDetailed CoursesItem'>
        <div className='column-info'>
            <a href={ course.url }>
                <div className='code'>{ course.code }</div>
                <div className='name'>{ course.name }</div>
                <div className='url'>
                    { course.url }
                </div>
            </a>
        </div>
        <div className='column-events'>
            <ul>
                { renderUnassignedIssues(l, course.repository) }
                { renderEvents(pref, course.news) }
            </ul>
        </div>
        { renderBuildStatus(l, course.repository) }
    </li>
)

export default withContext<Props>(CoursesTeachingItem)
