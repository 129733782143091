import React from 'react'
import { AppCtxO, withContext } from '../AppContext'
import './LoginAppeal.css'

const LoginAppeal: React.FC<AppCtxO> = ({ ctx }) => (
    <section className='LoginAppeal'>
        {ctx.l.loginAppeal.text}
        <form action='/_oauth/login' method='POST'>
            <button className='button'>{ctx.l.loginAppeal.loginButton}</button>
        </form>
    </section>
)

export default withContext<{}>(LoginAppeal)
