import React from 'react'
import { AppCtxO, withContext } from '../AppContext'
import { Semester } from '../courses'
import './SemesterSelector.css'

interface Props {
    currentSemester: Semester
    onSelect: (semester: Semester) => void
}

const SemesterSelector: React.FC<Props & AppCtxO> = ({ currentSemester, onSelect, ctx: { l }  }) => {
    // FIXME: This is a quick & nasty workaround, semester should be loaded from API.
    const codes = ['B151', 'B152', 'B161', 'B162', 'B171', 'B172', 'B181', 'B182', 'B191', 'B192', 'B201', 'B202', 'B211', 'B212', 'B221', 'B222', 'B231', 'B232']
    const semesters: { [semester in Semester]: string } = {
        ...codes.reduce((acc, code) => {
            acc[code] = `${l.semesterSelector.semester} ${code}`
            return acc
        }, {} as any),
        all: l.semesterSelector.all,
        current: l.semesterSelector.current,
    }

    return (
        <div className='SemesterSelector'>
            <h2 className='selected-item'>{ semesters[currentSemester] }</h2>
            <select onChange={ (e): void => onSelect(e.currentTarget.value as Semester) }>
                { Object.entries(semesters).map(s => (
                    <option value={ s[0] } selected={ currentSemester === s[0] } key={ s[0] }>
                        { s[1] }
                    </option>
                )) }
            </select>
        </div>
    )
}

export default withContext<Props>(SemesterSelector)
