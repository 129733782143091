import config from '../../../config.json'
import { makeAuthenticatedRequest, UserCredentials } from '../index'
import { Course } from '../../courses'

const DEFAULT_LIMIT_ITEMS = 64
const ENDPOINT_URL = config.api.endpoints.sirius

type LocalizedProperty = { [lang: string]: string }

export type EventType = 'assessment' | 'courseEvent' | 'exam' | 'laboratory' | 'lecture' | 'tutorial' // FIXME: add types missing in Sirius API documentation
export type EventTypeKeys = { [key in EventType]: string }

export interface TimetableEvent {
    id: number
    name?: LocalizedProperty
    courseName?: string
    course?: Course
    room?: string
    eventType: string
    startsAt: Date
    endsAt: Date
    deleted: boolean
}

/**
 * Returns string representation of the given *date* in format `YYYY-mm-dd`.
 */
function formatDate (date: Date): string {
    // Convert to local time.
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)

    // Strip time part.
    return date.toISOString().slice(0, 10)
}

const mapEventObject = (obj: { [key: string]: any }): TimetableEvent => ({
    courseName: obj.links ? obj.links.course : undefined,
    deleted: obj.deleted,
    endsAt: new Date(obj.ends_at),
    eventType: obj.event_type,
    id: obj.id,
    name: obj.name,
    room: obj.links ? obj.links.room : undefined,
    startsAt: new Date(obj.starts_at),
})

export async function getUserEvents (cred: UserCredentials): Promise<TimetableEvent[]> {
    const dateNow = new Date(Date.now())
    const dateNowTomorrow = new Date(Date.now() + 2 * 86400000)

    const data = await makeAuthenticatedRequest({
        method: 'get',
        params: {
            from: formatDate(dateNow),
            limit: DEFAULT_LIMIT_ITEMS,
            to: formatDate(dateNowTomorrow),
        },
        url: ENDPOINT_URL + 'people/' + cred.username + '/events',
    }, cred)

    return data.events.map(mapEventObject)
}
