import React from 'react'
import locales, { Locale } from './locales'

export interface AppPreferences {
    locale: 'en' | 'cs'
    theme: 'standard' | 'compact'
    upcomingVisible: boolean
}

export interface AppContextT {
    l: Locale
    pref: AppPreferences
}

export const defaultAppPref: AppPreferences = {
    locale: 'cs',
    theme: 'standard',
    upcomingVisible: true,
},

    AppContext = React.createContext<AppContextT>({
        l: locales.def,
        pref: defaultAppPref,
    })

export type AppCtx = AppContextT
export interface AppCtxO { ctx: AppContextT }

export function withContext<P> (Component: React.FC<P & AppCtxO>): React.FC<P> {
    return Object.assign((props: P) => (
        <AppContext.Consumer>
            {(context): JSX.Element => <Component {...props} ctx={context} />}
        </AppContext.Consumer>
    ), { displayName: Component.displayName + '#contextual' })
}
