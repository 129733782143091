import React from 'react'
import Cookies from 'universal-cookie'
import './App.css'

import { downloadCourses, UserCredentials } from './api'
import { downloadCourses as mockDownloadCourses } from './api/mock'
import { getPublicCourses } from './api/services/cpagesData'
import { TimetableEvent } from './api/services/sirius'
import { UserInfo, UserRole, UserCourseList } from './api/services/umapi'
import { AppContext, AppPreferences, defaultAppPref } from './AppContext'
import { Course, CourseList, mergeCourseLists, searchInCourses,
    Semester, toCourseList, splitCourseListByRoles } from './courses'
import locales, { Locale } from './locales'
import { recallSettings, storeSettings } from './settings'
import { is } from './utils'
import config from '../config.json'

import Courses from './components/Courses'
import Dashboard from './components/Dashboard'
import ErrorList from './components/ErrorList'
import Footer from './components/Footer'
import Heading from './components/Heading'
import LoginAppeal from './components/LoginAppeal'
import SearchBar from './components/SearchBar'
import SemesterSelector from './components/SemesterSelector'
import Settings from './components/Settings'
import Upcoming from './components/Upcoming'
import UserMenu from './components/UserMenu'

const cookies = new Cookies()

interface State {
    session?: UserCredentials
    user?: UserInfo
    allCourses: CourseList
    userCourses?: UserCourseList
    displayedSemester: Semester
    displayedCourses?: CourseList
    upcomingEvents?: TimetableEvent[]
    searchField: string
    searchFieldFocused: boolean
    preferences: AppPreferences
    errors: string[]
}

function hydrateSessionFromCookies (): UserCredentials | undefined {
    if (!cookies.get('oauth_access_token')) { return undefined }
    return {
        accessToken: cookies.get<string>('oauth_access_token'),
        refreshToken: cookies.get<string>('oauth_refresh_token') || '',
        username: cookies.get<string>('oauth_username'),
    }
}

export default class App extends React.Component<{ }, State> {
    public state: State = {
        allCourses: { },
        displayedSemester: 'all',
        errors: [],
        preferences: recallSettings(defaultAppPref),
        searchField: '',
        searchFieldFocused: false,
        session: hydrateSessionFromCookies(),
    }

    private settings = React.createRef<Settings>()

    public mergeToAllCourses = (list: CourseList): void => {
        this.setState({
            allCourses: mergeCourseLists(this.state.allCourses, list),
        })
    }

    public mergeToUserCourses = (result: Array<(Course | null | undefined)>, role: UserRole): void => {
        const merge = this.state.userCourses
            ? mergeCourseLists(toCourseList(this.state.userCourses[role]), toCourseList(result.filter(is)))
            : toCourseList(result.filter(is))

        this.setState({
            userCourses: this.state.userCourses ? {
                ...this.state.userCourses,
                [role]: merge,
            } : {
                student: [],
                teacher: [],
                [role]: merge,
            },
        })
    }

    public componentDidMount (): void {
        const downloadConfig = {
            session: this.state.session,
            handlers: {
                updateAllCourses: (allCourses: CourseList): void => this.setState({ allCourses }),
                saveUser: (user: UserInfo): void => this.setState({ user }),
                saveUserEvents: (upcomingEvents: TimetableEvent[]): void => this.setState({ upcomingEvents }),
                error: this.handleRequestError,
            },
        }

        // Downloads all required data from all APIs
        const data = config.data.startsWith('mock')
            ? mockDownloadCourses(downloadConfig, { })
            : downloadCourses(downloadConfig)

        data.then((allCourses: CourseList) => {
            // Enrich each upcomingEvent with course object (based on the courseName field).
            const upcomingEvents = this.state.upcomingEvents
                ? this.state.upcomingEvents.map(event => ({
                    ...event,
                    course: event.courseName ? allCourses[event.courseName] : undefined,
                }))
                : undefined

            this.setState({
                allCourses,
                userCourses: this.state.user
                    ? splitCourseListByRoles(allCourses, this.state.user.courseRoles)
                    : undefined,
                upcomingEvents,
            })
        })
    }

    public renderUserDashboard = (l: Locale): JSX.Element => (
        <>
            { this.state.preferences.upcomingVisible ? <Upcoming events={ this.state.upcomingEvents } /> : null }
            { this.state.userCourses ? (
                <div className='userCourses'>
                    <Courses
                        title={ l.dashboard.studyingTitle }
                        courses={ this.state.userCourses.student }
                        style='studying' />
                    <Courses
                        title={ l.dashboard.teachingTitle }
                        courses={ this.state.userCourses.teacher }
                        style='teaching' />
                </div>
            ) : '' }
        </>
    )

    public renderDashboard = (l: Locale): JSX.Element => (
        <Dashboard>
            { this.state.user ? this.renderUserDashboard(l) : <LoginAppeal /> }
            <SemesterSelector currentSemester={ this.state.displayedSemester } onSelect={this.handleSemesterChange} />
            <Courses courses={ this.state.displayedCourses || this.state.allCourses } style='default' maximum={ 20 } />
        </Dashboard>
    )

    public renderSearchResults = (l: Locale): JSX.Element => {
        const results = searchInCourses(this.state.allCourses, this.state.searchField)

        return (
            <div className={'search-results' + (this.state.searchFieldFocused ? ' search-field-focused' : '')}>
                <Courses title={ l.search.title } courses={ results } style='default' />
            </div>
        )
    }

    public handleQueryChange = (searchField: string): void => {
        this.setState({ searchField })
    }

    public handleSearchFieldEnterPress = (): void => {
        const results = searchInCourses(this.state.allCourses, this.state.searchField)

        if (results.length > 0 && is(results[0].url)) {
            window.location.href = results[0].url
        }
    }

    public handleSearchFieldFocusChange = (searchFieldFocused: boolean): void => {
        this.setState({ searchFieldFocused })
    }

    public handleSettingsToggle = (): void => {
        if (this.settings.current) {
            this.settings.current.toggle()
        }
    }

    public handleSettingsChange = (key: any, value: any): void => {
        const newPreferences = {
            ...this.state.preferences,
            [key]: value,
        }

        this.setState({ preferences: newPreferences })
        storeSettings(newPreferences)
    }

    public handleSemesterChange = (semester: Semester): void => {
        if (semester === this.state.displayedSemester) { return }
        if (semester === 'all') { this.setState({ displayedSemester: semester, displayedCourses: undefined }); return }
        getPublicCourses(semester).then(courses => {
            this.setState({ displayedSemester: semester, displayedCourses: toCourseList(courses) })
        })
        // TODO: cache this, don't trash and redownload each time
    }

    public handleLogout = (): void => {
        this.setState({ user: undefined })
    }

    public handleRequestError = (error: any): void => {
        const l = locales[this.state.preferences.locale]

        if (error.config) {
            const hostname = error.config.url.match('https?://([^/]+)/')[1],
                message = l.error.reqDescribe(hostname) + ' '
                + (error.response ? this.convertStatusCodeForHumans(error.response.status, l)
                    : l.error.reqNoResponse
                )
            this.setState({ errors: [ ...this.state.errors, message ] })
        }
    }

    public render (): JSX.Element {
        const locale = locales[this.state.preferences.locale]
        return (
            <AppContext.Provider value={{
                l: locale,
                pref: this.state.preferences,
            }}>
                <div className={ 'App' + (this.state.searchField ? ' in-search' : '')} style={ { overflow: 'hidden' } }>
                    <header>
                        <div className='container'>
                            <UserMenu
                                user={ this.state.user }
                                onSettingsClick={ this.handleSettingsToggle }
                                onLogout={ this.handleLogout }
                            />
                            <ErrorList errors={this.state.errors} />
                            <Heading />
                            <SearchBar
                                onQueryChange={ this.handleQueryChange }
                                onEnterPress={ this.handleSearchFieldEnterPress }
                                onFocusChange={ this.handleSearchFieldFocusChange }
                            />
                        </div>
                    </header>
                    { locale.broadcastMessage ? (
                        <div className='broadcast-message'>
                            { locale.broadcastMessage }
                        </div>
                    ) : null }
                    <div className='container main'>
                        { this.state.searchField ? this.renderSearchResults(locale) : this.renderDashboard(locale) }
                    </div>
                    <footer>
                        <Footer style='full' />
                    </footer>
                    <Settings
                        ref={ this.settings }
                        onClose={ this.handleSettingsToggle }
                        onSettingChange={ this.handleSettingsChange }
                    />
                </div>
            </AppContext.Provider>
        )
    }

    private convertStatusCodeForHumans = (status: number, locale: Locale): string => {
        if (status >= 500) { return locale.error.reqTemporaryErr }
        if (status === 401) { return locale.error.reqAuthErr }
        if (status >= 400) { return locale.error.reqRequestErr }
        return locale.error.reqOtherErr
    }
}
