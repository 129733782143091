import config from '../../config.json'
import React from 'react'
import { AppCtxO, AppPreferences, withContext } from '../AppContext'
import { Course } from '../courses'
import './CoursesItem.css'
import './CoursesItemDetailed.css'
import { formatShortDate } from '../utils'


type courseGradeKeys = keyof NonNullable<Course['grades']>
export type gradeKeys = { [key in courseGradeKeys]: string }

interface Props {
    course: Course
}

function renderEvents (pref: AppPreferences, news: Course['news']): JSX.Element | null {
    if (!news) { return null }
    return (
        <div className='column-events'>
            <ul>
                { news.slice(0, config.news.maxItems).map((item, idx) => <li key={ 'news-' + idx }>
                    <time dateTime={ item.date }>
                        <strong>{ formatShortDate(pref.locale, item.date) }</strong>
                    </time>&#8194;{ item.content }
                </li>) }
            </ul>
        </div>
    )
}

function renderGradeEntries (grades: NonNullable<Course['grades']>, labels: gradeKeys): JSX.Element[] {
    const entryKeys: Array<(keyof (typeof grades))> = [ 'grade', 'assesPoints', 'semesterPoints' ]
    return entryKeys.filter(key => (grades[key])).map(key => (
        <li key={ key }>
            <span className='value'>{ grades[key] }</span>
            <span className='description'>{ labels[key] }</span>
        </li>
    ))
}

function renderGrades (course: Course, labels: gradeKeys): JSX.Element {
    if (!course.grades) { return <div className='column-grades empty'></div> }
    const entries = renderGradeEntries(course.grades, labels)
    return (
        <div className={ 'column-grades' + (entries.length === 1 ? ' single' : '') }>
            <a href={course.grades.url} target='_blank' rel='noopener noreferrer'>
                <ul>{ entries }</ul>
            </a>
        </div>
    )
}

const CoursesStudyingItem: React.FC<Props & AppCtxO> = ({ course, ctx: { l, pref } }) => (
    <li className='CoursesStudyingItem CoursesItemDetailed CoursesItem'>
        { renderGrades(course, l.courses.gradeTypes) }
        <div className='column-info'>
            <a href={ course.url }>
                <div className='code'>{ course.code }</div>
                <div className='name'>{ course.name }</div>
                <div className='url'>
                    { course.url }
                </div>
            </a>
        </div>
        { renderEvents(pref, course.news) }
    </li>
)

export default withContext<Props>(CoursesStudyingItem)
