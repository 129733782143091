import { Course, CourseList, toCourseList } from '../courses'
import Chance from 'chance'
import { DownloadConfiguration } from './index'
import { CourseUserRole, UserRole } from './services/umapi'
import { TimetableEvent } from './services/sirius'

const chance = new Chance()

interface MockConfiguration {
    noTeaching?: boolean
    noStudying?: boolean
}

function generateNews (): Course['news'] {
    return chance.n(() => ({
        id: chance.guid(),
        content: chance.sentence({ words: chance.integer({ min: 6, max: 12 }) }),
        link: chance.url(),
        date: chance.date().toISOString(),
    }), chance.integer({ min: 0, max: 3 }))
}

function generateCourse (code: string): Course {
    const numOfIssues = chance.integer({ min: 0, max: 5 })

    return {
        code,
        name: chance.sentence({ words: 3, punctuation: false }),
        url: chance.url(),
        semester: 'B' + chance.integer({ min: 170, max: 192 }),
        programmeType: chance.pickone(['bachelor', 'master', 'doctoral']),
        season: chance.pickone(['summer', 'winter']),
        department: chance.natural({ min: 18101, max: 18106 }),
        news: generateNews(),
        grades: {
            url: chance.url(),
            grade: chance.pickone([ chance.string({ length: 1, pool: 'ABCDEF' }), undefined ]),
            semesterPoints: chance.pickone([ chance.integer({ min: 0, max: 100 }), undefined ]),
            assesPoints: chance.pickone([ chance.integer({ min: 0, max: 100 }), undefined ]),
        },
        repository: {
            url: chance.url(),
            buildStatus: chance.pickone(['success', 'failed']),  // FIXME: There are more statuses!
            buildUrl: chance.url(),
            unassignedIssues: numOfIssues,
            latestIssues: chance.n(() => chance.sentence(), numOfIssues),
            lastChange: {
                desc: chance.sentence(),
                author: chance.name(),
                time: chance.timestamp(),
                url: chance.url(),
            },
        },
    }
}

/**
 * Mocks downloaded data, trying to replicate results from real
 * downloadCourses() keeping similarity best as possible.
 *
 * Takes the same arguments as downloadCourses from index.ts, plus mock
 * randomness configuration.
 *
 * @param config Download configuration object
 * @returns A CourseList promise.
 */
export function downloadCourses (config: DownloadConfiguration, mockConfig: MockConfiguration): Promise<CourseList> {
    const coursesCodes = chance.n(() => ('BI-' + chance.string({ length: 3, pool: 'ABCDEF' })), 64)
    config.handlers.updateAllCourses(toCourseList(coursesCodes.map(code => ({ code }))))

    const courseRoles: CourseUserRole[] = coursesCodes.slice(0, 16).map(course => ({
        course,
        role: chance.pickone([UserRole.Student, UserRole.Teacher]),
    }))

    const currentDate = new Date(Date.now())
    const numOfEvents = chance.integer({ min: 1, max: 6 })

    const events: TimetableEvent[] = coursesCodes.slice(16, 16 + numOfEvents).map(courseName => ({
        id: chance.integer({ min: 11111, max: 99999 }),
        name: {
            cs: chance.sentence({ words: 3, punctuation: false }),
        },
        courseName,
        room: chance.pickone([ 'TH:A-', 'T9:', 'T2:' ]) + chance.integer({ min: 111, max: 999 }),
        deleted: false,
        eventType: chance.pickone([ 'lecture', 'tutorial', 'exam', 'laboratory' ]),
        startsAt: chance.date(
            { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() }
        ) as Date,
        endsAt: chance.date(
            { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() }
        ) as Date,
    }))

    const userName = chance.name().split(' ')

    config.handlers.saveUser({
        username: chance.string({ length: 8 }),
        personalNumber: chance.integer({ min: 111111, max: 999999 }),
        firstName: userName[0],
        lastName: userName[1],
        fullName: userName.join(' '),
        courseRoles,
    })

    setTimeout(() => config.handlers.saveUserEvents(events), 600)

    return new Promise((res): void => {
        const list = toCourseList(coursesCodes.map(generateCourse))
        setTimeout(() => res(list), 1500)
    })
}
