import { AppPreferences } from './AppContext'

const SETTINGS_LS_KEY = 'preferences'

export function recallSettings (defaultPrefs: AppPreferences): AppPreferences {
    const localStored = localStorage.getItem(SETTINGS_LS_KEY)
    if (localStored === null) { return defaultPrefs }

    return JSON.parse(localStored)
}

export function storeSettings (settings: AppPreferences): void {
    localStorage.setItem(SETTINGS_LS_KEY, JSON.stringify(settings))
}
