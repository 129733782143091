import config from '../../../config.json'
import { makeAuthenticatedRequest, UserCredentials } from '../index'
import { Course } from '../../courses'
import { is } from '../../utils'

const ENDPOINT_URL = config.api.endpoints.umapi

export enum UserRole { Student = 'student', Teacher = 'teacher' }
export interface CourseUserRole { course: string, role: UserRole }

export interface UserInfo {
    username: string
    personalNumber: number
    firstName: string
    lastName: string
    fullName: string
    courseRoles: CourseUserRole[]
}

export type UserCourseList = { [key in UserRole]: Course[] }

function extractRoles (roles: any[]): CourseUserRole[] {
    return roles
        .map((role: string) => /^P-([^\s-]+-[^\s-]+)-(.+)$/.exec(role))
        .filter(is)
        .map(matches => ({
            course: matches[1],
            role: matches[2].startsWith('UCITEL') ? UserRole.Teacher : UserRole.Student,
        }))
}

const mapUserInfoObject = (obj: { [key: string]: any }): UserInfo => ({
    courseRoles: extractRoles(obj.roles),
    firstName: obj.firstName,
    fullName: obj.fullName,
    lastName: obj.lastName,
    personalNumber: obj.personalNumber,
    username: obj.username,
})

export async function getUserInfo (cred: UserCredentials): Promise<UserInfo> {
    const data = await makeAuthenticatedRequest({
        method: 'get',
        url: ENDPOINT_URL + 'people/' + cred.username,
    }, cred)

    return mapUserInfoObject(data)
}
