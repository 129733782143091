import React from 'react'
import { AppCtxO, withContext } from '../AppContext'
import './SearchBar.css'

interface Props {
    onQueryChange: (query: string) => void
    onEnterPress: () => void
    onFocusChange: (focused: boolean) => void
}

const SearchBar: React.FC<Props & AppCtxO> = ({ onQueryChange, onEnterPress, onFocusChange, ctx: { l } }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
        onQueryChange(event.currentTarget.value)

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        onEnterPress()
    }

    return (
        <section className='SearchBar'>
            <form onSubmit={ handleFormSubmit }>
                <input
                    autoFocus
                    type='search'
                    onChange={ handleInputChange }
                    name='query'
                    placeholder={ l.search.placeholder }
                    className='search-input'
                    autoComplete='off'
                    onFocus={ (): void => onFocusChange(true) }
                    onBlur={ (): void => onFocusChange(false) }
                />
            </form>
            { /* <button className="button button-light advanced-search">Advanced search</button> */}
        </section>
    )
}

export default withContext<Props>(SearchBar)
