export function formatShortDate (locale: 'en' | 'cs', date: Date | string): string {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    const day = date.getDate()
    const month = date.getMonth() + 1  // months are 0-based

    return locale === 'cs'
        ? `${day}.\u2009${month}.`
        : `${day}/${month}`
}

export function is<T> (value: T | null | undefined): value is T {
    return value !== null && value !== undefined
}
